body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.__floater__body {
  transform-origin: center;
  animation: scaleIn 200ms ease-in;
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* Salesforce Live Chat Styling */
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #c5da00;
  font-family: 'Arial', sans-serif;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #c5da00;
}

.message {
  background-color: #c5da00 !important;
  border-style: none !important;
  color: white !important;
  font-size: initial !important;
}

.embeddedServiceHelpButton .uiButton {
  min-width: 21em !important;
}

.embeddedServiceHelpButton .helpButton {
  bottom: 50px !important;
}

.embeddedServiceSidebar.layout-docked .dockableContainer {
  bottom: 50px !important;
}

[embeddedservice-chatheader_chatheader-host] {
  background-color: #c5da00 !important;
}

.embeddedServiceSidebarMinimizedDefaultUI {
  bottom: 50px !important;
  background: #c5da00 !important;
  min-width: 15em !important;
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton {
  background-color: #c5da00 !important;
}

.embeddedServiceLiveAgentStateChat .endChatContainer .endChatButton {
  margin: 2px 24px !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
  background: #c5da00 !important;
}

.embeddedServiceLiveAgentStateChatAvatar.isLightningOutContext .agentIconColor0 {
  background: #c5da00 !important;
}

.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover {
  background-color: #c5da00 !important;
}

.embeddedServiceSidebarButton.uiButton--inverse,
.embeddedServiceSidebarButton.uiButton--inverse:disabled {
  background-color: #c5da00 !important;
}

button.helpButtonDisabled,
button.helpButtonDisabled::before,
button.helpButtonDisabled:active,
button.helpButtonDisabled:focus,
button.helpButtonDisabled:hover,
button.helpButtonDisabled:visited {
  background-color: #cdcdcd !important;
  cursor: default !important;
}

button.helpButtonEnabled,
button.helpButtonEnabled::before,
button.helpButtonEnabled:active,
button.helpButtonEnabled:focus,
button.helpButtonEnabled:hover,
button.helpButtonEnabled:visited {
  background-color: #c5da00 !important;
  cursor: default !important;
}

.embeddedServiceSidebarButton .label {
  color: var(--lwc-colorTextInverse, rgb(255, 255, 255)) !important;
}

button.helpButtonDisabled > .helpButtonLabel > .message {
  background-color: #cdcdcd !important;
}
